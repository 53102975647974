const TeamContent = {
  TEAM_INTRO: `The staff at Collins Orthodontics is dedicated to providing the
  highest quality of orthodontic care in the safest and most
  effective manner possible. Our staff members are compassionate,
  friendly and accommodating. They stay current with new technology
  and techniques by attending continuing education courses on a
  consistent basis. Each member of Dr. Collins’ staff is
  professional, highly skilled and available to answer any concerns
  or questions you may have regarding your orthodontic treatment.`,

  TEAM_MEMBERS: [
    {
      name: 'Dr. Collins',
      title: 'Orthodontist / Owner',
      photo: 'DrCollins',
      isOwner: true,
    },
    {
      name: 'Alyssa',
      title: 'Clinical Team',
      photo: 'Alyssa',
      isOwner: false,
    },
    {
      name: 'Bri',
      title: 'Clinical Team',
      photo: 'Bri',
      isOwner: false,
    },
    {
      name: 'Cathern',
      title: 'Administrative Team',
      photo: 'Cathern',
      isOwner: false,
    },
    {
      name: 'Danielle',
      title: 'Administrative Team',
      photo: 'Danielle',
      isOwner: false,
    },
    {
      name: 'Emma',
      title: 'Clinical Team',
      photo: 'Emma',
      isOwner: false,
    },
    {
      name: 'Hannah',
      title: 'Clinical Team',
      photo: 'Hannah',
      isOwner: false,
    },
    { name: 'Hope', title: 'Clinical Team', photo: 'Hope', isOwner: false },
    {
      name: 'Jen',
      title: 'Administrative Team',
      photo: 'Jen',
      isOwner: false,
    },
    { name: 'Jena', title: 'Clinical Team', photo: 'Jena', isOwner: false },
    {
      name: 'Jenna',
      title: 'Administrative Team',
      photo: 'Jenna',
      isOwner: false,
    },
    {
      name: 'Jennifer',
      title: 'Administrative Team',
      photo: 'Jennifer',
      isOwner: false,
    },
    {
      name: 'Kelsey',
      title: 'Administrative Team',
      photo: 'Kelsey',
      isOwner: false,
    },
    {
      name: 'Lannie',
      title: 'Administrative Team',
      photo: 'Lannie',
      isOwner: false,
    },
    {
      name: 'Laura',
      title: 'Administrative Team',
      photo: 'Laura',
      isOwner: false,
    },
    {
      name: 'LeeAnn',
      title: 'Clinical Team',
      photo: 'Leeann',
      isOwner: false,
    },
    {
      name: 'Litha',
      title: 'Clinical Team',
      photo: 'Litha',
      isOwner: false,
    },
    {
      name: 'Martha',
      title: 'Administrative Team',
      photo: 'Martha',
      isOwner: false,
    },
    {
      name: 'Nikki',
      title: 'Clinical Team',
      photo: 'Nikki',
      isOwner: false,
    },
    { name: 'Nuy', title: 'Clinical Team', photo: 'Nuy', isOwner: false },
    {
      name: 'Pone',
      title: 'Administrative Team',
      photo: 'Pone',
      isOwner: false,
    },
    {
      name: 'Tessa',
      title: 'Clinical Team',
      photo: 'Tessa',
      isOwner: false,
    },
    {
      name: 'Vanessa',
      title: 'Administrative Team',
      photo: 'Vanessa',
      isOwner: false,
    },
    {
      name: 'Veronica',
      title: 'Clinical Team',
      photo: 'Veronica',
      isOwner: false,
    },
  ],
}

export default TeamContent
