import React from 'react'
import { rhythm } from '../utils/typography'
import { themeStyles, colors } from '../utils/theme'
import { GatsbyImage } from 'gatsby-plugin-image'

const styles = {
  centerOnSmall: {
    textAlign: 'center',
  },
  mainContainer: {
    display: 'flex',
    marginBottom: 0,
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '340px',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...themeStyles.textPadding,
    paddingBottom: 0,
    paddingTop: 0,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
}

const PhotoAndBio = ({ name, title, image }) => {
  return (
    <div css={styles.mainContainer}>
      <GatsbyImage
        image={image}
        alt={name}
        key={image.id}
        css={{
          marginRight: rhythm(1),
          marginBottom: 0,
          height: rhythm(20),
          width: '100%',
          maxWidth: '300px',
        }}
      />
      <div css={styles.innerContainer}>
        <h3
          css={[
            styles.centerOnSmall,
            { margin: 0, marginBottom: rhythm(1 / 4), fontWeight: 'normal' },
          ]}
        >
          {name}
        </h3>
        <p
          css={[
            styles.centerOnSmall,
            { color: colors.brightBlue, margin: 0, letterSpacing: 1.9 },
          ]}
        >
          {title}
        </p>
      </div>
    </div>
  )
}

export default PhotoAndBio
