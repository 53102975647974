import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
const { v4: uuidv4 } = require('uuid')

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import PhotoAndBio from '../../components/PhotoAndBio'

// App content
import TeamContent from '../../content/our-team'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, colors, presets, metrics } from '../../utils/theme'

import '../../styles/our-team.css'
class Page extends React.Component {
  render() {
    const teamIntro = TeamContent.TEAM_INTRO
    // Set image for each staff member and unique image id
    _.forEach(TeamContent.TEAM_MEMBERS, (e) => {
      e.image = this.props.data[e.photo].childImageSharp.gatsbyImageData
      e.image.id = uuidv4()
    })

    const admin_employee_elements = TeamContent.TEAM_MEMBERS.filter(
      (member) =>
        member.isOwner == false && member.title == 'Administrative Team'
    ).map((person) => <PhotoAndBio {...person} key={person.name} />)

    const clinical_employee_elements = TeamContent.TEAM_MEMBERS.filter(
      (member) => member.isOwner == false && member.title == 'Clinical Team'
    ).map((person) => <PhotoAndBio {...person} key={person.name} />)

    const ownerElements = TeamContent.TEAM_MEMBERS.filter(
      (member) => member.isOwner == true
    ).map((person) => <PhotoAndBio {...person} key={person.name} />)

    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="OUR TEAM"
            bannerKey={'OUR_TEAM'}
            backgroundPosition="60% 50%"
            minHeight="360px"
          />
          <div
            css={[
              {
                [presets.Tablet]: { borderBottom: 0 },
                marginBottom: metrics.defaultMargin,
              },
              themeStyles.textPadding,
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.brightBlue,
                textAlign: 'center',
              },
            ]}
          >
            <h1
              css={{
                letterSpacing: 10,
                marginTop: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                color: 'white',
              }}
            >
              OUR TEAM
            </h1>
            <p
              css={[
                themeStyles.elementMargin,
                { marginTop: 0, color: 'white', fontSize: '16px' },
              ]}
            >
              {teamIntro}
            </p>
          </div>
          <div
            css={{
              marginTop: rhythm(-1),
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {ownerElements}
          </div>
          <div>
            <h2
              css={{
                letterSpacing: 5,
                marginTop: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                color: '#53D2E7',
                textAlign: 'center',
                marginBottom: 20,
              }}
            >
              Administrative Team
            </h2>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {admin_employee_elements}
            </div>
          </div>
          <div>
            <h2
              css={{
                letterSpacing: 5,
                marginTop: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                color: '#53D2E7',
                textAlign: 'center',
                marginBottom: 20,
              }}
            >
              Clinical Team
            </h2>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {clinical_employee_elements}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    Alyssa: file(relativePath: { eq: "team/2024/Alyssa.jpg" }) {
      ...fluidImage400
    }
    Bri: file(relativePath: { eq: "team/2024/Bri.jpg" }) {
      ...fluidImage400
    }
    Cathern: file(relativePath: { eq: "team/2024/Cathern.jpg" }) {
      ...fluidImage400
    }
    Danielle: file(relativePath: { eq: "team/2024/Danielle.jpg" }) {
      ...fluidImage400
    }
    Emma: file(relativePath: { eq: "team/2024/Emma.jpg" }) {
      ...fluidImage400
    }
    DrCollins: file(relativePath: { eq: "team/2024/DrCollins2.jpg" }) {
      ...fluidImage400
    }
    Hannah: file(relativePath: { eq: "team/2024/Hannah.jpg" }) {
      ...fluidImage400
    }
    Hope: file(relativePath: { eq: "team/2024/Hope.jpg" }) {
      ...fluidImage400
    }
    Jen: file(relativePath: { eq: "team/2024/Jen.jpg" }) {
      ...fluidImage400
    }
    Jena: file(relativePath: { eq: "team/2024/Jena.jpg" }) {
      ...fluidImage400
    }
    Jenna: file(relativePath: { eq: "team/2024/Jenna.jpg" }) {
      ...fluidImage400
    }
    Jennifer: file(relativePath: { eq: "team/2024/Jennifer.jpg" }) {
      ...fluidImage400
    }
    Kelsey: file(relativePath: { eq: "team/2024/Kelsey.jpg" }) {
      ...fluidImage400
    }
    Lannie: file(relativePath: { eq: "team/2024/Lannie.jpg" }) {
      ...fluidImage400
    }
    Laura: file(relativePath: { eq: "team/2024/Laura.jpg" }) {
      ...fluidImage400
    }
    Leeann: file(relativePath: { eq: "team/2024/Leeann.jpg" }) {
      ...fluidImage400
    }
    Litha: file(relativePath: { eq: "team/2024/Litha.jpg" }) {
      ...fluidImage400
    }
    Martha: file(relativePath: { eq: "team/2024/Martha.jpeg" }) {
      ...fluidImage400
    }
    Nikki: file(relativePath: { eq: "team/2024/Nikki.jpg" }) {
      ...fluidImage400
    }
    Nuy: file(relativePath: { eq: "team/2024/Nuy.jpg" }) {
      ...fluidImage400
    }
    Pone: file(relativePath: { eq: "team/2024/Pone.jpg" }) {
      ...fluidImage400
    }
    Tessa: file(relativePath: { eq: "team/2024/Tessa.jpg" }) {
      ...fluidImage400
    }
    Vanessa: file(relativePath: { eq: "team/2024/Vanessa.jpg" }) {
      ...fluidImage400
    }
    Veronica: file(relativePath: { eq: "team/2024/Veronica.jpg" }) {
      ...fluidImage400
    }
  }
`

export default Page
